import React from "react";
import styles from "./FooterBanner.module.css";
import Local from "./ImageLoader";
import Image from "next/image";
import { useTranslation } from "../context/TranslationContext";

//TODO Açıklama Yazısını bağla.

const FooterBanner = ({ currentRoutingTypeId }) => {
  const { footerBannerTitle, footerBannerCaption } = useTranslation();
  return (
    <div
      className={
        "container -mb-40 relative  shadow-footerBanner h-auto lg:h-80  bg-no-repeat lg:bg-right-top bg-contain flex flex-col justify-center lg:pl-20 py-14 lg:py-0 " +
        (currentRoutingTypeId == "Reservation" ? "" : "sm:mt-32 ") +
        styles.mobileBgPosition
      }
    >
      <div className="absolute inset-0 bg-brand-blue-3 ">
        <Image
          src="/icons/FooterBanner.jpg"
          loader={Local}
          alt=""
          layout="fill"
          className="object-top object-cover  lg:object-contain lg:object-right-top"
        ></Image>
      </div>
      <strong
        className="font-bold relative leading-8 text-brand-black text-2xl lg:text-3xl w-80 max-w-full"
        dangerouslySetInnerHTML={{
          __html: footerBannerTitle
            .replace("{", "<span class='text-white'>")
            .replace("}", "</span>"),
        }}
      ></strong>
      <p className="text-15 text-brand-black opacity-50 mt-3 lg:w-5/12">
        {footerBannerCaption}
      </p>
    </div>
  );
};

export default FooterBanner;
